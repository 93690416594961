/* welcome page before log in */
.skip-link {
  text-decoration: none;
  padding: 0.3em 0.5em;
  display: inline-block;
  left: 2vw;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.15s;
  z-index: 1000;
  border-radius: 2px;
}

.skip-link:focus-visible {
  color: hsl(212, 100%, 43%);
  top: 1vh;
  background-color: hsl(0, 0%, 95%);
  transform: translateY(0%);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.pagelayout {
  display: grid;
  min-height: 100vh;
  max-height: max-content;
  grid-template-rows: max-content 1fr max-content max-content;
}

.pagelayout__header {
  background-color: var(--green-darker);
  color: #fff;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.pagelayout__header a:hover,
.pagelayout__header a:focus-visible {
  background-color: rgba(255, 255, 255, 0.08);
}

.pagelayout__content {
  background-color: #fff;
  color: #222;
}

.pagelayout__footer {
  background-color: var(--green-darker);
  color: white;
  width: 100%;
}

.footer__wrap {
  padding: 0.5rem 4vmin;
  max-width: 76rem;
  margin: 0 auto;
}

.pagelayout__footer a {
  color: var(--green-lighter);
}

.pagelayout__footer a:hover {
  color: hsl(75, 53%, 70.5%);
}

.header__mybringlogo {
  padding: 0.45rem 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.header__mybringlogo svg {
  fill: #fff;
  width: 6rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.header__bringlogo {
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 0.25rem 0.5rem 0.45rem;
}

.header__bringlogo svg {
  width: 6.25rem;
}

.header__login {
  padding: 0 1rem 0.25rem;
  margin-left: auto;
  font-weight: 500;
  font-size: 1.17rem;
  color: #eee;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header__login:hover,
.header__login:focus {
  color: #fff;
}

.header__login-text {
  margin-right: 0.4rem;
}

.header__login-icon {
  width: 1.5rem;
  fill: currentColor;
}

.banner {
  background: var(--green-lighter);
  margin-bottom: 3vmin;
}

.banner__wrap {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 76rem;
  margin: 0 auto;
  padding: 4vmin 4vmin 5vmin;
}

.banner__section {
  flex: 1 1 30rem;
  max-width: 30rem;
}

.register-cta {
  display: flex;
  color: var(--green-darker);
  padding: 0.65rem 1.2rem 1rem;
  margin-bottom: 0.25rem;
  background-color: var(--green);
  line-height: 1.2;
  border-radius: 2px;
  text-decoration: none;
}

.register-cta svg {
  flex: 0 0 2.2rem;
  width: 2.2rem;
  height: 2.2rem;
  fill: var(--green-dark);
  margin-right: 0.8rem;
  margin-top: 0.2rem;
}

.register-cta:hover {
  color: #002f19;
  background-color: var(--green-hover);
}

.login-cta {
  display: none;
  color: #fff;
  padding: 0.65rem 1.2rem 1rem;
  margin-bottom: 4vmin;
  background-color: var(--green-darker);
  line-height: 1.2;
  border-radius: 2px;
}

.login-cta svg {
  flex: 0 0 2.2rem;
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.8rem;
  margin-top: 0.2rem;
}

.login-cta:hover {
  color: white;
  background-color: #183e2a;
}

.features {
  max-width: 76rem;
  margin: 0 auto;
  padding: 1rem 0.5rem 5rem 4vmin;
}

.features__title {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 2rem;
  line-height: 1.2;
}

.features__group {
  display: flex;
  flex-wrap: wrap;
}

.features__item {
  flex: 1 1 23rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-right: 4vmin;
  margin-bottom: 1rem;
  max-width: 29rem;
}

.features__item svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  fill: #444;
}

.features__item h2 {
  font-weight: 500;
}

.customerservice {
  padding: 2rem 0 1rem;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  border-bottom: 1px solid var(--green-dark);
  margin-bottom: 0.5rem;
}

.customerservice__logo {
  padding: 0.25rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 2px;
}

.customerservice__logo:hover,
.customerservice__logo:focus-visible {
  background-color: rgba(255, 255, 255, 0.08);
}

.customerservice__logo svg {
  height: 3.5rem;
  width: 9rem;
}

.customerservice__list {
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.customerservice__grid {
  display: flex;
  flex-flow: row wrap;
  max-width: 16rem;
}

.customerservice__terms {
  flex: 0 1 auto;
  margin-bottom: 2rem;
}

.terms {
  display: flex;
  flex-flow: row wrap;
  font-size: 0.83rem;
}

@media screen and (max-width: 44em) {
  .header__login {
    display: none;
  }

  .login-cta {
    display: flex;
  }
}
